<template>
    <InitLayout>
      <!-- logo placeholder -->
      <b-card
        no-body
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-1 px-lg-2
          py-3
          shadow-hard
          forgot-password-container-card
        "
        style="background-color: rgba(255, 255, 255, 0.4); backdrop-filter: blur(5px);"
      >
      <b-row class="w-full my-3" no-gutters>
        <b-img
        src="@/assets/The Logo.png"
          class="px-0 w-auto"
          height="70px"
          alt="Personal PAC"
        ></b-img>
      </b-row>
        <!-- headings -->
        <b-col
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-row class="mb-3" no-gutters>
            <font-awesome-icon
              class="icon-container bg-white shadow-soft fa-3x"
              icon="user-shield"
            />
          </b-row>
          <b-row no-gutters>
            <h3 class="text-center font-primary font-weight-bold main-heading">
              Password Reset
            </h3>
          </b-row>
          <b-row no-gutters>
            <span class="text-center font-primary mb-4 sub-heading">
              Please enter your email address, we will send a verification code
            </span>
          </b-row>
        </b-col>
        <!-- forgot-password form -->
        <form
          id="forgot-password-form"
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            px-2 px-lg-1
            w-100
          "
          @submit.prevent="submitFn"
        >
          <!-- full row input (Username) -->
          <b-row
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center
              w-100
            "
            no-gutters
          >
            <b-col cols="12" md="10">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                :state="emailError.status"
                :invalidFeedback="`${emailError.message}`"
                label="Email Address"
                placeholder="user@example.com"
                v-model="email"
                trim
                form="forgot-password-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- error status -->
          <b-row
            v-if="formError.status"
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-center
              w-100
            "
            no-gutters
          >
            <span class="text-danger text-small text-center">{{
              this.formError.message
            }}</span>
          </b-row>
          <!-- form action -->
          <b-row
            class="
              d-flex
              flex-row
              justify-content-center justify-content-md-between
              align-items-center
              px-5 px-lg-4 px-xl-5
              w-100
            "
            no-gutters
          >
            <!-- login button -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center align-items-md-start
                justify-content-center
                px-0
                py-2
              "
              cols="12"
              md="6"
              lg="5"
            >
              <FormButton
                isBlock
                class="font-weight-normal register-button"
                type="submit"
                :isLoading="isAPILoading"
                :isDisabled="isAPILoading"
                @click.native="submitFn"
                style="background-color: #00AFF2;"
              >
                Submit
              </FormButton>
            </b-col>
            <!-- forgot password button/link -->
            <b-col
              class="
                d-flex
                flex-column
                align-items-center align-items-md-end
                justify-content-center
                px-0
                py-2
              "
              cols="12"
              md="6"
              lg="5"
            >
              <b-link to="/login" class="register-btn-name text-dark"
                >Remember password?</b-link
              >
            </b-col>
          </b-row>
        </form>
      </b-card>
    </InitLayout>
  </template>
  
  <script>
  import InitLayout from "@/give-proudly-layout/give-proudly-initLayout";
  import FormInput from "@/components/Form/FormInput";
  import FormButton from "@/components/Form/FormButton";
  import { EmailVerify } from "@/services/forgetPassword.service";
  import CheckEmail from "@/util/CheckEmail";
  
  export default {
    name: "ForgotPasswordEmail",
    components: {
      InitLayout,
      FormInput,
      FormButton,
    },
    data() {
      return {
        isAPILoading: false,
        email: "",
        errorEmail: null,
        emailError: {
          status: null,
          message: "",
        },
        formError: {
          status: null,
          message: "",
        },
      };
    },
    watch: {
      email(val) {
        this.formError.status = false;
        if (val) {
          if (CheckEmail(val) == true) this.emailError.status = true;
          else {
            this.emailError.status = false;
            this.emailError.message = "Please enter a valid email address";
          }
        } else {
          this.emailError.status = false;
          this.emailError.message = "Please fill in your email address";
        }
      },
    },
    methods: {
      submitFn() {
        if (this.email && CheckEmail(this.email)) {
          let payload = {
            email: this.email,
          };
          this.isAPILoading = true;
          console.log(payload);
          EmailVerify(payload)
            .then((res) => {
              if (
                res.data.message == "success" ||
                res.data.status == "Conflictd"
              ) {
                this.$router.push({
                  name: "ForgotPasswordCode",
                });
                localStorage.setItem("email", this.email);
              } else {
                this.emailError.status = false;
                this.emailError.message = res.data.details;
              }
            })
            .catch((err) => {
              this.formError.status = true;
              this.formError.message = err.message;
            })
            .finally(() => (this.isAPILoading = false));
        } else {
          this.emailError.status = false;
          this.emailError.message = "Please fill in your email address";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .forgot-password-container-card {
    width: 80%;
    min-width: 300px;
    min-height: 400px;
    max-width: 600px;
    border-radius: 14px;
    margin-left: 180px;
  }
  
  /* heading stylings */
  .forgot-password-container-card .main-heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  
  .forgot-password-container-card .sub-heading {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
  
  .forgot-password-container-card .icon-container {
    background: white;
    padding: 15px;
    height: 50px;
    width: 50px;
    border: 1px solid var(--stroke-light);
    box-sizing: border-box;
    border-radius: 36px;
  }
  
  /* .forgot-password-container-card .register-button {
    opacity: 1;
    transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
      color 200ms ease-in;
  }
  .forgot-password-container-card .register-button:hover {
    opacity: 0.7;
    box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
  } */
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .forgot-password-container-card {
      min-height: fit-content;
    }
  }
  
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .forgot-password-container-card {
      width: 90%;
    }
  }
  </style>
  