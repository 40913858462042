<template>
  <InitLayout>
    <!-- logo placeholder -->
    <b-row class="w-full my-3" no-gutters>
      <b-img
        src="@/assets/personalPac-log.svg"
        class="px-0 w-auto"
        height="50px"
        alt="Personal PAC"
      ></b-img>
    </b-row>
    <b-card
      no-body
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        bg-white
        px-1 px-lg-2
        py-3
        shadow-hard
        forgot-password-container-card
      "
    >
      <!-- headings -->
      <b-col
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <b-row class="mb-3" no-gutters>
          <font-awesome-icon
            class="icon-container bg-white shadow-soft fa-3x"
            icon="user-shield"
          />
        </b-row>
        <b-row no-gutters>
          <h3 class="text-center font-primary font-weight-bold main-heading">
            Forgot Password - Reset
          </h3>
        </b-row>
        <b-row no-gutters>
          <span class="text-center font-primary mb-4 sub-heading">
            Please fill in your new password
          </span>
        </b-row>
      </b-col>
      <!-- change password form -->
      <form
        id="change-password-form"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center
          px-2 px-lg-1
          w-100
        "
        @submit.prevent="submitFn"
      >
        <!-- full row input (Password) -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="password-group"
              id="password-group-input"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              label="Password"
              v-model="password"
              placeholder="Minimum 6 characters"
              trim
              form="change-password-form"
              type="password"
            ></FormInput>
          </b-col>
        </b-row>
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="confirm-password-group"
              id="confirm-password-group-input"
              :state="confirmNewPasswordError.status"
              :invalidFeedback="`${confirmNewPasswordError.message}`"
              label="Confirm Password"
              v-model="confirmNewPassword"
              placeholder="Minimum 6 characters"
              trim
              form="change-password-form"
              type="password"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- error status -->
        <b-row
          v-if="formError.status"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <span class="text-danger text-small text-center">{{
            this.formError.message
          }}</span>
        </b-row>
        <!-- form action -->
        <b-row
          class="
            d-flex
            flex-row
            justify-content-center justify-content-md-between
            align-items-center
            px-5 px-lg-4 px-xl-5
            w-100
          "
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center align-items-md-start
              justify-content-center
              px-0
              py-2
            "
            cols="12"
            md="6"
            lg="5"
          >
            <FormButton
              isBlock
              class="font-weight-normal register-button"
              type="submit"
              :isLoading="isAPILoading"
              :isDisabled="isAPILoading"
              @click.native="submitFn"
            >
              Submit
            </FormButton>
          </b-col>
          <!-- forgot password button/link -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center align-items-md-end
              justify-content-center
              px-0
              py-2
            "
            cols="12"
            md="6"
            lg="5"
          >
            <b-link to="/login" class="register-btn-name text-dark"
              >Remember password?</b-link
            >
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";
import { PasswordUpdated } from "@/services/forgetPassword.service";

export default {
  name: "ForgotPasswordChangeCode",
  components: {
    InitLayout,
    FormInput,
    FormButton,
  },
  data() {
    return {
      password: null,
      confirmNewPassword: null,
      confirmNewPasswordShow: false,
      isAPILoading: false,
      confirmNewPasswordError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      formError: {
        status: null,
        message: "",
      },
      isReset: false,
    };
  },
  watch: {
    password(val) {
      this.errorFn(val);
    },
    confirmNewPassword(val) {
      this.errorFn(this.password, val);
    },
  },
  computed: {
    resetEmail() {
      return localStorage.getItem("email");
    },
  },
  methods: {
    async submitFn() {
      var errors = this.errorFn();
      if (errors.length == 0) {
        let payload = {
          email: this.resetEmail,
          password: this.password,
        };
        try {
          this.isAPILoading = true;
          let { data } = await PasswordUpdated(payload);
          if (data.code == 200 && data.message == "success") {
            let payloadNotify = {
              isToast: true,
              title: "Password Reset",
              content: "Your password has been changed successfully, you will be redirected to the login page shortly.",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            this.resetFn();

            // redurect to login after 3 seconds
            setTimeout(() => {
              this.$router.push({
                name: "Login",
              });
            }, 3000);
          } else {
            this.formError.status = true;
            this.formError.message = `An error occured when saving the password: ${data.details}`;

            // clear error message in the span class
            setTimeout(() => {
              this.formError.status = false;
              this.formError.message = "";
            }, 3000);

            //  notifivation
            let payloadNotify = {
              isToast: true,
              title: "Password Reset",
              content: "Unable to reset/change password.",
              list: [data.details, data.errorCode],
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.isAPILoading = false;
        }
      } else {
        let payloadNotify = {
          isToast: true,
          title: "Password Reset",
          content: "Unable to send password reset request.",
          list: errors,
          variant: "danger",
        };

        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn(
      password = this.password,
      confirmPassword = this.confirmNewPassword
    ) {
      var pwdErrors = this.validatePassword(password);
      var pwdConfirmErrors = this.validateConfirmPassword(confirmPassword);
      if (pwdErrors.length > 0) {
        this.passwordError.status = false;
        this.passwordError.message = pwdErrors[0];
      } else this.passwordError.status = true;

      if (pwdErrors.length == 0 && pwdConfirmErrors.length > 0) {
        this.confirmNewPasswordError.status = false;
        this.confirmNewPasswordError.message = pwdConfirmErrors[0];
      } else if (pwdConfirmErrors.length == 0)
        this.confirmNewPasswordError.status = true;
      else this.confirmNewPasswordError.status = null;

      return [...pwdErrors, ...pwdConfirmErrors];
    },
    validatePassword(val = this.password) {
      var errorList = [];
      if (!val) errorList.push("Please fill in the password");
      else if (val.length < 6)
        errorList.push("A minimum length of 6 characters is required");
      return errorList;
    },
    validateConfirmPassword(val = this.confirmNewPassword) {
      var errorList = [];
      if (!val) errorList.push("Please fill in your confirm password again");
      else if (val.length < 6)
        errorList.push(
          "A minimum length of 6 characters needed for the confirm password"
        );
      else if (val != this.password) errorList.push("Passwords do not match");
      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.password = null;
      this.confirmNewPassword = null;
      this.passwordError.status = null;
      this.confirmNewPasswordError.status = null;
      this.formError.status = null;
      setTimeout(() => (this.isReset = false), 100);
    },
  },
};
</script>

<style scoped>
.forgot-password-container-card {
  width: 80%;
  min-width: 300px;
  min-height: 400px;
  max-width: 600px;
  border-radius: 14px;
}
/* heading stylings */
.forgot-password-container-card .main-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.forgot-password-container-card .sub-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.forgot-password-container-card .icon-container {
  background: white;
  padding: 15px;
  height: 60px;
  border: 1px solid var(--stroke-light);
  box-sizing: border-box;
  border-radius: 36px;
}
.forgot-password-container-card .register-button {
  opacity: 1;
  transition: opacity 200ms ease-in, box-shadow 200ms ease-in,
    color 200ms ease-in;
}

.forgot-password-container-card .register-button:hover {
  opacity: 0.7;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.15) !important;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .forgot-password-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .forgot-password-container-card {
    width: 90%;
  }
}
</style>
