import Instance from "./instance";

export const EmailVerify = (payload) => {
  return Instance.instance.post(`/forgot/code`, payload);
};

export const CodeVerify = (payload) => {
  return Instance.instance.post(`/forgot/verify`, payload);
};

export const PasswordUpdated = (payload) => {
  return Instance.instance.put(`/forgot/update`, payload);
};
